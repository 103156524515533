"use client";

import React, { createContext, useState } from "react";

const OverlayContext = createContext({
  id: null,
  setId: (e) => null,
  stations: null,
  setStations: (e) => null,
  header: { title: null, headerText: null },
  setHeader: (e) => null,
  content: { text: null, linkTo: null },
  setContent: (e) => null,
  show: null,
  setShow: (e) => null,
});

export const OverlayContextProvider = ({ children }) => {
  const [id, setId] = useState<string>(null);
  const [stations, setStations] = useState<string>(null);
  const [show, setShow] = useState<boolean>(false);

  const [header, setHeader] = useState<{ title; headerText }>();
  const [content, setContent] = useState<{ text; linkTo }>();

  return (
    <OverlayContext.Provider
      value={{
        id,
        setId,
        stations,
        setStations,
        header,
        setHeader,
        content,
        setContent,
        show,
        setShow,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
};

export default OverlayContext;
