"use client";

import Image from "next/image";

const Icon = ({
  icon,
  color,
}: {
  icon: string;
  color?: "surrogate" | "white";
}) => {
  const getColor = () => {
    switch (color) {
      case "white":
        return "#fff";
      default:
        return "#c7125c";
    }
  };

  const getIcon = () => {
    let iconUrl = undefined;
    try {
      iconUrl = require(`../../assets/icons/${icon}.svg`);
    } catch (error) {
      console.error(error);
    }

    return iconUrl;
  };

  return getIcon() ? (
    <Image src={getIcon()} alt="" style={{ color: getColor() }} />
  ) : null;
};

export default Icon;
