"use client";

import { useContext, useEffect, useRef } from "react";

import Icon from "@/components/Icon/Icon";
import Image from "next/image";
import OverlayContext from "@/context/OverlayContext";
import classNames from "classnames";
import polyImageViolet from "@/assets/charging-station.svg";
import styles from "./Overlay.module.scss";

export interface IOverlay {
  id: string;
  setId: (e: string) => void;
  header?: { title?: string; headerText?: string };
  setHeader?: (e: any) => void;
  content?: { text?: string; linkTo?: string };
  setContent?: (e: any) => void;
  show: boolean;
  setShow: (e: boolean) => void;
}

const Overlay = () => {
  const refContent = useRef(null);
  const refOutside = useRef(null);
  const { id, header, content, show, setShow } =
    useContext<IOverlay>(OverlayContext);

  const onOutsideClick = (event) => {
    const conditionalCallback = () => {
      if (refOutside) {
        if (
          refContent.current &&
          !refContent.current.contains(event.target) &&
          refOutside.current &&
          refOutside.current.contains(event.target)
        ) {
          setShow(false);
        }
      } else {
        if (refOutside.current && !refOutside.current.contains(event.target)) {
          setShow(false);
        }
      }
    };

    if (show) {
      conditionalCallback();
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else if (!show) {
      document.body.style.overflow = "";
    }
  }, [show]);

  useEffect(() => {
    document.addEventListener("mousedown", onOutsideClick);
    return () => {
      document.removeEventListener("mousedown", onOutsideClick);
    };
  });

  return (
    <div
      className={classNames(styles.overlay, { [styles.showOverlay]: show })}
      ref={refOutside}
    >
      <div
        className={classNames(styles.close, { [styles.showClose]: show })}
        onClick={() => setShow(false)}
      >
        <Icon icon="close" />
      </div>
      <div
        className={classNames(styles.wrapper, { [styles.showWrapper]: show })}
        ref={refContent}
      >
        <div className={styles.header}>
          <div className={styles.idWrapper}>
            <div className={styles.id}>
              <p>{id}</p>
            </div>
            <Image src={polyImageViolet} alt="Polygon" />
          </div>
          {header?.headerText && <p>{header.headerText}</p>}
        </div>
        <div className={styles.content}>
          {header?.title && <h2>{header.title}</h2>}
          {content?.text && <p>{content.text}</p>}
        </div>
      </div>
    </div>
  );
};
export default Overlay;
